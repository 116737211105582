import {importAll} from "../Utils/utils";

const icons = importAll(require.context('./images/icons', false, /\.(png|jpe?g|svg|gif)$/));

const socialLinks = {
  'Tiktok':
{url: "https://www.tiktok.com/@DorcaPals",
class: "fab fa-tiktok"
}, 'Youtube':
{url: "https://www.youtube.com/channel/UCYSDVa1ULW4v0SLpfQVQ9XA?sub_confirmation=1",
class: "fab fa-youtube"
}, 'Discord':
{url: "https://discord.gg/z3PzTsY68X",
class: "fab fa-discord"
},  'Twitch':
{url: "https://twitch.tv/DorcaPals", 
class: "fab fa-twitch"
},  'X/Twitter':
{url: "https://twitter.com/DorcaPals",
class: "fab fa-twitter"
},  'Pinterest':
{url: "https://www.pinterest.com/DorcaPals/",
class: "fab fa-pinterest"
},  'Instagram':
{url: "https://www.instagram.com/DorcaPals/",
class: "fab fa-instagram"
},  

};

const commLinks = {
  'Vgen':
{url: "https://vgen.co/DorcaPals",
icon: icons["vgen.png"]
},

};

const merchLinks = {
  'Ko-Fi':
{url: "https://ko-fi.com/DorcaPals",
icon: icons["kofi.png"]
}, 'Etsy':
{url: "https://etsy.com/shop/DorcaPals",
icon: icons["etsy.svg"]
},

};

const supportLinks= {
  'Ko-Fi':
  {url: "https://ko-fi.com/DorcaPals",
icon: icons["kofi.png"]
}, 'Throne':
{url: "https://throne.com/DorcaPals",
icon: icons["throne.png"]
},


}

export {socialLinks, commLinks, merchLinks, supportLinks};