import React from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import styles from './Socials.module.css';
import { importAll } from '../../Utils/utils';

//import PixivIcon from "./PixivIcon";
import {merchLinks, commLinks, socialLinks, supportLinks} from '../../Assets/socialLinks';

const icons = importAll(require.context('./../../Assets/images/bg', false, /\.(png|jpe?g|svg|gif)$/));

function createButtonLinkList(entries) {
    return (<>
    {Object.entries(entries).map(([key, val]) => <MDBRow className='mt-3'>
    <a href={val.url} target="_blank" className={' me-4 text-reset'}>
        <MDBBtn rounded className={styles.button +  'mx-2 col-10'} color="info"> 
            <div className={styles.logo}>
                {val.class ? 
                    <i className={styles.icon + " " + val.class}/>:
                    <img src={val.icon} className={styles.icon + ' text-reset'}/>
                }
                <b className={styles.label}>{key}</b>
            </div>
        </MDBBtn>
    </a>
</MDBRow>)}
</>)
}

export default function Socials() {
  return (
  <>
    <title>Pkae2 Socials</title>  

        <MDBContainer className={'text-md-start mt container-fluid'}>

    
            <MDBCol className = {styles.col + " col-6 text-center"} >

            <img src={icons['pkaeDorca.png']} className={styles.pfp + ' img-fluid rounded-circle' }/>
            
            <div className={styles.intro}>
            Dorca Vtuber <br/>
            Live2D Animator and Illustrator =)
            </div>

            <MDBRow className={styles.header}>
                <h1>Commissions</h1>
            </MDBRow>
            
            {createButtonLinkList(commLinks)}

            <MDBRow className={styles.header}>
                <h1>Merch</h1>
            </MDBRow>
            {createButtonLinkList(merchLinks)}

            <MDBRow className={styles.header}>
                <h1>Socials</h1>
            </MDBRow>

            {createButtonLinkList(socialLinks)}
        
            <MDBRow className={styles.header}>
                <h1>Tips</h1>
            </MDBRow>
        
            {createButtonLinkList(supportLinks)}

        </MDBCol>
        </MDBContainer>
  </>
  );
}