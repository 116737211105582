import React from 'react';
import Carousel from '../../components/Carousel/Carousel';
import Intro from '../../components/Intro';
import Socials from '../../components/Socials/Socials';

export default function HomePage(){
    return (
        <>
        <Socials></Socials>
        {/* <Carousel></Carousel>
        <Intro></Intro> */}
        </>
    );

};